import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";

export interface MenuState {
  label: string;
}

export type InitialState = MenuState;

const initialState: InitialState = {
  label: ""
};

// Selectors
const mainSelector = (state: RootState) => state.sideMenu;

export const getMenu = createSelector(mainSelector, state => {
  return {
    label: state.label
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLabel(label: string) {
    this.state.label = label;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function setLabelMenu(
  label: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async dispatch => {
    dispatch(actions.setLabel(label));
  };
}
