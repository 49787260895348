import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";
import { setLabelMenu } from "./sideMenu";

export type Patient = {
  patiID: string;
  name: string;
  email: string;
  phone: string;
  doctor: string;
  doctName: string;
};

export interface PatientState {
  patient: Patient;
  patients: Array<Patient>;
  namePatients: Array<{ patiID; name }>;
  loading: boolean;
}

export type InitialState = PatientState;

const initialState: InitialState = {
  patient: {
    patiID: "",
    name: "",
    email: "",
    phone: "",
    doctor: "",
    doctName: ""
  },
  patients: [],
  loading: false,
  namePatients: []
};

// Selectors
const mainSelector = (state: RootState) => state.patients;

/* export const getLoading = createSelector(
  mainSelector,
  (state) => {
    state.loading
  }
); */

export const getPatients = createSelector(mainSelector, state => {
  return {
    loading: state.loading,
    patients: state.patients,
    patient: state.patient,
    namePatients: state.namePatients
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(a: boolean) {
    this.state.loading = a;
  }

  tokenSwap(formData: any, json: any) {
    let token = json.data.item.jwt
      ? json.data.item.jwt.replace("Bearer ", "")
      : "";
    return {
      form: formData,
      token,
      response: json,
      receivedAt: Date.now()
    };
  }

  startLoading() {
    this.state.loading = true;
  }

  listPatients(patients: Array<Patient>) {
    this.state.patients = patients;
  }

  getPatient(patient: Patient) {
    this.state.patient = patient;
  }

  getNamePatients(patients: Array<Patient>) {
    this.state.namePatients = patients.map(patient => {
      return { patiID: patient.patiID, name: patient.name };
    });
  }

  getNamePatientsAndDoctors(patients: Array<Patient>) {
    this.state.namePatients = patients.map(patient => {
      return { patiID: patient.patiID, name: patient.name };
    });
  }

  addPatient(patients: Array<Patient>) {
    this.state.patients = patients;
  }

  clearPatient() {
    this.state.patient = {
      patiID: "",
      name: "",
      email: "",
      phone: "",
      doctor: "",
      doctName: ""
    };
  }

  clearPatients() {
    this.state.patients = [];
  }

  endLoading() {
    this.state.loading = false;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchListPatients(
  label: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(setLabelMenu(label));
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/patients`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch(actions.listPatients(r.data.data.items));
        return r.data.data.items;
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: "" //(e.response.data.error || {}).message,
          }) as any
        );
      });
  };
}

export function createPatient(formData: {
  email: string;
  name: string;
  doctID: string;
  info: { phone: string };
}): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(setLabelMenu("patient"));
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.API_URL}/patients`, formData, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(() => {
        dispatch(fetchListPatients("patients"));
        dispatch(
          newNotification("patients", {
            status: "success",
            message: "Paciente cadastrado com sucesso!"
          }) as any
        );
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("patients", {
            status: "error",
            message: "Falha ao cadastar paciente"
          }) as any
        );
      });
  };
}

export function fetchPatient(
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(setLabelMenu("patients"));
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/patients/${id}`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch(actions.getPatient(r.data.data.item));
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message
          }) as any
        );
      });
  };
}

export function fetchNamePatients(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/patients`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch(actions.getNamePatients(r.data.data.items));
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message || ""
          }) as any
        );
      });
  };
}

export function updatePatient(
  formData: {
    email: string;
    name: string;
    doctID: string;
    info: { phone: string };
  },
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/patients/${id}`, formData, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(() => {
        dispatch(fetchListPatients("patients"));
        dispatch(
          newNotification("patients", {
            status: "success",
            message: "Paciente atualizado com sucesso!"
          }) as any
        );
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: "Falha ao editar paciente"
          }) as any
        );
      });
  };
}
