import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";
import moment from "moment";
import { setLabelMenu } from "./sideMenu";
import { fetchListEventsBySearch, fetchListEvents } from "./calendar";

export type Schedule = {
  patiID: string;
  name: string;
  email: string;
  phone: string;
  doctor: string;
  scheID: string;
};

export type ScheduleForm = {
  doctID: string;
  plan: string;
  startAt: string;
  endAt: string;
  info: { phone?: string };
};

export type Appointment = {
  schedID: string;
  appoID: string;
  patiID: string;
  patiName: string;
  startAppointment: string;
  status: "scheduled" | "confirmed";
};

export type AppointmentForm = [];

type HourDict = {
  start: string;
  end: string;
};

type HourConfig = {
  sunday?: HourDict;
  monday?: HourDict;
  thuesday?: HourDict;
  wednesday?: HourDict;
  thursday?: HourDict;
  friday?: HourDict;
  saturday?: HourDict;
};

export interface ScheduleState {
  schedule: Schedule;
  scheduleByID: { [scheID: string]: Schedule };
  appointmentsBySchedID: { [scheID: string]: Array<Appointment> };
  schedules: Array<Schedule>;
  loading: boolean;
  minimumTime: string;
  transitionTime: string;
  value: Array<object>;
  codeErrorSchedule: string;
  availableDoctorsHours: any;
  namePatientsAndDoctors: any;
  appointments: Array<object>;
  timeLimit: Array<object>;
  hourConfig: HourConfig;
}

export type InitialState = ScheduleState;

const initialState: InitialState = {
  schedule: {
    patiID: "",
    name: "",
    email: "",
    phone: "",
    doctor: "",
    scheID: "",
  },
  scheduleByID: {},
  appointmentsBySchedID: {},
  schedules: [],
  loading: false,
  minimumTime: "",
  transitionTime: "",
  value: [],
  codeErrorSchedule: "",
  availableDoctorsHours: "",
  namePatientsAndDoctors: "",
  appointments: [],
  timeLimit: [],
  hourConfig: {
    sunday: undefined,
    monday: undefined,
    thuesday: undefined,
    wednesday: undefined,
    thursday: undefined,
    friday: undefined,
    saturday: undefined,
  },
};

// Selectors
const mainSelector = (state: RootState) => state.schedules;

/* export const getLoading = createSelector(
  mainSelector,
  (state) => {
    state.loading
  }
); */
export const getHourSchedule = createSelector(mainSelector, (state) => {
  return {
    schedule: state.hourConfig,
  };
});
export const getSchedules = createSelector(mainSelector, (state) => {
  return {
    //loading: state.loading,
    schedules: state.schedules,
    schedule: state.schedule,
    appointments: state.appointments,
    minimumTime: state.minimumTime,
    transitionTime: state.transitionTime,
    value: state.value,
    codeErrorSchedule: state.codeErrorSchedule,
    availableDoctorsHours: state.availableDoctorsHours,
    namePatientsAndDoctors: state.namePatientsAndDoctors,
    timeLimit: state.timeLimit,
  };
});

export const getSchedule = (state: InitialState, props: any) =>
  createSelector(mainSelector, (state) => {
    return {
      loading: state.loading,
      schedules: state.schedules,
      schedule: state.scheduleByID[props.match.params.sche_id] || {},
      appointments:
        state.appointmentsBySchedID[props.match.params.sche_id] || [],
      minimumTime: state.minimumTime,
      transitionTime: state.transitionTime,
      value: state.value,
      codeErrorSchedule: state.codeErrorSchedule,
      availableDoctorsHours: state.availableDoctorsHours,
      namePatientsAndDoctors: state.namePatientsAndDoctors,
      timeLimit: state.timeLimit,
    };
  });

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(a: boolean) {
    this.state.loading = a;
  }

  tokenSwap(formData: any, json: any) {
    let token = json.data.item.jwt
      ? json.data.item.jwt.replace("Bearer ", "")
      : "";
    return {
      form: formData,
      token,
      response: json,
      receivedAt: Date.now(),
    };
  }

  startLoading() {
    this.state.loading = true;
  }

  endLoading() {
    this.state.loading = false;
  }
  loadHourConfig(value: HourConfig) {
    this.state.hourConfig = value;
  }

  clearSchedule() {
    this.state.schedule = {
      patiID: "",
      name: "",
      email: "",
      phone: "",
      doctor: "",
      scheID: "",
    };
  }

  getTimeLimit(t: any) {
    this.state.timeLimit = t;
  }

  getSchedule(s: Schedule) {
    this.state.schedule = s;
    this.state.scheduleByID[s.scheID] = s;
  }

  clearAppointment() {
    this.state.appointments = [];
  }

  getAppointments(a: Array<Appointment>) {
    this.state.appointments = a;
  }

  loadScheduleAppointments(scheID: string, a: Array<Appointment>) {
    this.state.appointmentsBySchedID[scheID] = a;
  }

  getValues(v: Array<object>) {
    this.state.value = v;
  }

  getMinimumTime(t: any) {
    this.state.minimumTime = t.toString();
  }

  getCodeError(e: string) {
    this.state.codeErrorSchedule = e;
  }

  getAvailableHours(h: any) {
    this.state.availableDoctorsHours = h
      .map((config) => {
        const slots = config.slots.map((slot) => {
          const hoursConfig = {
            start: moment(
              `${moment.utc(config.date).format("YYYY-MM-DD")} ${slot.start}`
            )
              .subtract(3, "hours")
              .toDate(),
            end:
              slot.end === "00:00" || slot.end === "00:00:00"
                ? moment(
                    moment(
                      `${moment.utc(config.date).format("YYYY-MM-DD")} ${
                        slot.end
                      }`
                    ).toDate()
                  )
                    .subtract(3, "hours")
                    .add(1, "day")
                    .toDate()
                : moment(
                    `${moment.utc(config.date).format("YYYY-MM-DD")} ${
                      slot.end
                    }`
                  )
                    .subtract(3, "hours")
                    .toDate(),
            title: config.doctName,
          };
          return hoursConfig;
        });
        return slots;
      })
      .reduce((a, b) => [...a, ...b], []);
  }

  getNamePatientsAndDoctors(patients: Array<any>, doctors: Array<any>) {
    const nameDoctors = doctors.map((doctor) => {
      return { doctID: doctor.doctID, name: doctor.name, type: "doctID" };
    });

    const namePatients = patients.map((patient) => {
      return { patiID: patient.patiID, name: patient.name, type: "patiID" };
    });

    this.state.namePatientsAndDoctors = [...nameDoctors, ...namePatients];
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function dispatchLabelMenu(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch) => {
    dispatch(setLabelMenu("calendar"));
  };
}

export function fetchScheduleAndAppointments(
  scheID: string
): ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.clearAppointment());
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    const apposReq = axios.get(
      `${ENVS.API_URL}/appointments?scheID=${scheID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    );
    const schedReq = axios.get(`${ENVS.API_URL}/schedules/${scheID}`, {
      headers: {
        Authorization: apiToken,
      },
    });
    return Promise.all([apposReq, schedReq])
      .then(([appo, sched]) => {
        dispatch(
          actions.loadScheduleAppointments(scheID, appo.data.data.items)
        );
        dispatch(actions.getSchedule(sched.data.data.item));
        return {
          ...sched.data.data.item,
          appointments: appo.data.data.items,
        };
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message,
          }) as any
        );
      })
      .finally(() => {
        dispatch(actions.endLoading());
      });
  };
}

export function fetchConfig(): ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/configs/schedule-minimum_time`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.getValues(r.data.data.item.value));
        dispatch(actions.getMinimumTime(r.data.data.item.value.minimum_time));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message,
          }) as any
        );
      });
  };
}

export function fetchConfigSchedule(): ThunkAction<
  Promise<any>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/configs/schedule-hour_config`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.loadHourConfig(r.data.data.item.value));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message,
          }) as any
        );
      });
  };
}

export function createScheduleAndAppointment(
  s: ScheduleForm,
  a: AppointmentForm
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.API_URL}/schedules`, s, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        if (a.length > 0) {
          a.forEach((element: any) => {
            const formData = {
              patiID: element.patiID,
              scheID: r.data.data.item.scheID,
              status: element.status,
              type: element.type,
              startAt: element.startAt,
            };
            return axios.post(`${ENVS.API_URL}/appointments`, formData, {
              headers: {
                Authorization: apiToken,
              },
            });
          });
        }
        dispatch(
          newNotification("schedule", {
            status: "success",
            message: "Consulta marcada com sucesso",
          })
        );
      })
      .then((r) => {})
      .catch((e) => {
        dispatch(
          fetchAvailableHours(
            s.doctID,
            moment(s.startAt).toDate(),
            moment(s.endAt).toDate()
          )
        );
        dispatch(actions.endLoading());
        dispatch(
          actions.getCodeError(e.response.data && e.response.data.error.code)
        );
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message || "",
          }) as any
        );
        throw new Error(e.response.data.error.message);
      });
  };
}

export function updateScheduleAndAppointment(
  s: ScheduleForm,
  a: AppointmentForm,
  scheID
): ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/schedules/${scheID}`, s, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        return axios
          .get(`${ENVS.API_URL}/appointments?scheID=${scheID}`, {
            headers: {
              Authorization: apiToken,
            },
          })
          .then((r) => {
            r.data.data.items.forEach((element) =>
              axios.delete(`${ENVS.API_URL}/appointments/${element.appoID}`, {
                headers: {
                  Authorization: apiToken,
                },
              })
            );
          });
      })
      .then((r) => {
        if (a.length > 0) {
          a.forEach((element: any) => {
            const formData = {
              patiID: element.patiID,
              scheID: scheID,
              status: element.status,
              type: element.type,
              startAt: element.startAt,
            };
            return axios.post(`${ENVS.API_URL}/appointments`, formData, {
              headers: {
                Authorization: apiToken,
              },
            });
          });
        }
        dispatch(
          newNotification("schedule", {
            status: "success",
            message: "Agendamento editado com sucesso",
          }) as any
        );
      })
      .then(() => {
        dispatch(
          fetchListEvents(
            moment().subtract(1, "month").format("YYYY-MM-DD"),
            moment().add(1, "month").format("YYYY-MM-DD")
          )
        );
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Agendamento efetuado com sucesso",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(
          fetchAvailableHours(
            s.doctID,
            moment(s.startAt).toDate(),
            moment(s.endAt).toDate()
          )
        );
        dispatch(actions.endLoading());
        dispatch(actions.getCodeError(e.response.data.error.code));
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message || "",
          }) as any
        );
        throw new Error(e.response.data.error.message);
      });
  };
}

export function fetchAvailableHours(
  doctID,
  startAt,
  endAt
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    const startDate = moment(startAt).format("YYYY-MM-DD").toString();
    const endDate = moment(endAt).endOf("week").format("YYYY-MM-DD").toString();
    return axios
      .get(
        `${ENVS.API_URL}/avaliability?startDate=${startDate}&endDate=${endDate}&doctID=${doctID}`,
        {
          headers: {
            Authorization: apiToken,
          },
        }
      )
      .then((r) => {
        dispatch(actions.getAvailableHours(r.data.data.items));
        dispatch(fetchConfig());
        return r.data.data.items;
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(actions.getCodeError(e.response.data.error.code || []));
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data.error.message || "",
          }) as any
        );
      });
  };
}

export function fetchNamePatientsAndDoctors(): ThunkAction<
  Promise<any>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/patients`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        axios
          .get(`${ENVS.API_URL}/doctors`, {
            headers: {
              Authorization: apiToken,
            },
          })
          .then((h) => {
            dispatch(
              actions.getNamePatientsAndDoctors(
                r.data.data.items,
                h.data.data.items
              )
            );

            axios
              .get(`${ENVS.API_URL}/configs/schedule-hour_config_flex`, {
                headers: {
                  Authorization: apiToken,
                },
              })
              .then((p: any) =>
                dispatch(actions.getTimeLimit(p.data.data.item.value))
              );
          });
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: "", //(e.response.data.error || {}).message,
          }) as any
        );
      });
  };
}

export function dispatchSearchEvents(
  patiID,
  doctID
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch) => {
    dispatch(fetchListEventsBySearch(patiID, doctID));
  };
}
