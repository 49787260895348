import createReducer from "app/utility/createReducer";
import { RootState } from "app/ducks/state";
import { Action } from "ducks";
import { createSelector } from "reselect";

export enum Actions {
  NEW_NOTIFICATION = "NEW_NOTIFICATION",
  DISMISS_FIRST_NOTIFICATION = "DISMISS_FIRST_NOTIFICATION",
  DISMISS_ALL_NOTIFICATION = "DISMISS_ALL_NOTIFICATION"
}

export interface NotificationMessage {
  status: string;
  message: string;
}
export interface NotificationState {
  [key: string]: Array<NotificationMessage>;
}
export type InitialState = {
  [key: string]: Array<NotificationMessage>;
};

// Reducers
const initialState: InitialState = {
  main: []
};

export const noteReducer = createReducer(initialState, {
  [Actions.DISMISS_FIRST_NOTIFICATION](state: InitialState, a: Action) {
    (state[a.payload.section] || []).shift();
    return state;
  },
  [Actions.DISMISS_ALL_NOTIFICATION](state: InitialState, a: Action) {
    state[a.payload.section] = [];
    return state;
  },
  [Actions.NEW_NOTIFICATION](
    state: InitialState,
    a: {
      payload: { note: NotificationMessage; section: string; error?: boolean };
      type: string;
    }
  ) {
    state[a.payload.section] = state[a.payload.section] || [];
    state[a.payload.section].push(a.payload.note);
    return state;
  }
});

// Selectors
const mainSelector = (state: RootState) => state.notification;

export const getNotificationFrom = (section: string) =>
  createSelector(mainSelector, state => ({
    notification: (state[section] || [])[0],
    count: (state[section] || []).length
  }));

// Actions
export function dismissFirstNotificationFrom(section: string) {
  return {
    type: Actions.DISMISS_FIRST_NOTIFICATION,
    payload: { section }
  };
}

export function dismissAllNotificationsFrom(section: string) {
  return {
    type: Actions.DISMISS_ALL_NOTIFICATION,
    payload: { section }
  };
}

export function newNotification(
  section: string,
  note: NotificationMessage,
  error?: boolean
) {
  return {
    type: Actions.NEW_NOTIFICATION,
    payload: { section, note, error }
  };
}
