import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";

export interface PasswordState {
  id: string;
}

export type InitialState = PasswordState;

const initialState: InitialState = {
  id: ""
};

// Selectors
const mainSelector = (state: RootState) => state.password;

export const getPassword = createSelector(mainSelector, state => {
  return {
    id: state.id
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setID(id: string) {
    this.state.id = id;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchRecoverPassword(formData: {
  email: string;
}): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.BASE_URL}/auth/password-recover`, formData, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(() => {
        dispatch(
          newNotification("auth", {
            status: "success",
            message: "Email enviado com sucesso!"
          }) as any
        );
      })
      .catch(e => {
        dispatch(
          newNotification("auth", {
            status: "error",
            message: e.response.data.error.message || ""
          }) as any
        );
      });
  };
}

export function fetchResetPassword(
  formData: {
    password: string;
  },
  resetID: string,
  verification: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(
        `${ENVS.BASE_URL}/auth/password-reset/${resetID}/${verification}`,
        formData,
        {
          headers: {
            Authorization: apiToken
          }
        }
      )
      .then(() => {
        dispatch(
          newNotification("auth", {
            status: "success",
            message: "Senha redefinida com sucesso!"
          }) as any
        );
      })
      .catch(e => {
        dispatch(
          newNotification("auth", {
            status: "error",
            message: e.response.data.error.message || ""
          }) as any
        );
      });
  };
}
