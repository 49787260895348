import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";

export interface Outdoor {
  doctID: string;
  name: string;
  avatar: string;
  treatment: string;
  roomID: string;
  label: string;
  specialties: Array<any>;
}

export interface OutdoorState {
  outdoor: Outdoor;
  loading: boolean;
}

export type InitialState = OutdoorState;

const initialState: InitialState = {
  outdoor: {
    doctID: "",
    name: "",
    avatar: "",
    treatment: "",
    roomID: "",
    label: "",
    specialties: []
  },
  loading: false
};

// Selectors
const mainSelector = (state: RootState) => state.outdoor;

export const getOutdoor = createSelector(mainSelector, state => {
  return {
    outdoor: state.outdoor,
    loading: state.loading
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(loading: boolean) {
    this.state.loading = loading;
  }

  startLoading() {
    this.state.loading = true;
  }

  endLoading() {
    this.state.loading = false;
  }

  getOutdoor(outdoor: Outdoor) {
    this.state.outdoor = outdoor;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchOutdoor(
  roomID: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/outdoor/${roomID}`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch(actions.getOutdoor(r.data.data.item));
        dispatch(actions.endLoading());
        return r.data.data.item;
      })
      .catch(e => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("outdoor", {
            status: "error",
            message: ""
          }) as any
        );
      });
  };
}
