import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";
import { setLabelMenu } from "./sideMenu";

export type Doctor = {
  name: string;
  email: string;
  phone: string;
  doctID: string;
  inactiveAt: string;
  info: {
    schedule: any;
    phone: string;
    avatar: string;
    treatment: string;
    allowScheduling: string;
  };
  userID: string;
};

export interface DoctorState {
  doctor: any;
  doctors: Array<Doctor>;
  loading: boolean;
  nameDoctors: Array<{ doctID; name }>;
  specialties: Array<any>;
}

export type InitialState = DoctorState;

const initialState: InitialState = {
  doctor: {
    name: "",
    email: "",
    phone: "",
    doctID: "",
    inactiveAt: "",
    info: {
      schedule: "",
      phone: "",
      avatar: "",
      treatment: "",
      allowScheduling: "",
    },
    userID: "",
  },
  doctors: [],
  loading: false,
  nameDoctors: [],
  specialties: [],
};

// Selectors
const mainSelector = (state: RootState) => state.doctors;

/* export const getLoading = createSelector(
  mainSelector,
  (state) => {
    state.loading
  }
); */

export const getDoctors = createSelector(mainSelector, (state) => {
  return {
    loading: state.loading,
    doctors: state.doctors,
    doctor: state.doctor,
    nameDoctors: state.nameDoctors,
    specialties: state.specialties,
  };
});

export const getNameDoctors = createSelector(mainSelector, (state) => {
  return {
    nameDoctors: state.nameDoctors,
    loading: state.loading,
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(a: boolean) {
    this.state.loading = a;
  }

  tokenSwap(formData: any, json: any) {
    let token = json.data.item.jwt
      ? json.data.item.jwt.replace("Bearer ", "")
      : "";
    return {
      form: formData,
      token,
      response: json,
      receivedAt: Date.now(),
    };
  }

  startLoading() {
    this.state.loading = true;
  }

  listDoctors(doctors: Array<Doctor>) {
    this.state.doctors = doctors;
  }

  getDoctor(doctor: Doctor) {
    this.state.doctor = doctor;
  }

  getNameDoctors(doctors: Array<Doctor>) {
    this.state.nameDoctors = doctors.map((doctor) => {
      if (
        doctor.info.treatment === " " ||
        doctor.info.treatment === undefined
      ) {
        return { doctID: doctor.doctID, name: doctor.name };
      } else {
        return {
          doctID: doctor.doctID,
          name: doctor.info.treatment + " " + doctor.name,
        };
      }
    });
  }

  addDoctor(doctors: Array<Doctor>) {
    this.state.doctors = doctors;
  }

  deleteDoctor(doctors: Array<Doctor>) {
    this.state.doctors = doctors;
  }

  listSpecialties(r: any) {
    this.state.specialties = r;
  }

  clearDoctor() {
    this.state.doctor = {
      name: "",
      email: "",
      phone: "",
      doctID: "",
      inactiveAt: "",
      info: {
        schedule: "",
        phone: "",
        avatar: "",
        treatment: "",
        allowScheduling: "",
      },
      userID: "",
    };
  }

  clearDoctors() {
    this.state.doctors = [];
  }

  endLoading() {
    this.state.loading = false;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchListDoctors(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(setLabelMenu("doctor"));
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/doctors`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.listDoctors(r.data.data.items));
        return r.data.data.items;
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("doctor", {
            status: "error",
            message: "Erro ao carregar profissionais de saúde",
          }) as any
        );
        throw e;
      });
  };
}

export function fetchNameDoctors(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/doctors`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.getNameDoctors(r.data.data.items));
        dispatch(actions.endLoading());
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.response.data && e.response.data.error.message,
          }) as any
        );
      });
  };
}

export function deleteDoctor(
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .delete(`${ENVS.API_URL}/doctors/${id}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(fetchListDoctors());
        dispatch(
          newNotification("doctor", {
            status: "success",
            message: "Profissional de saúde excluído com sucesso",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("doctor", {
            status: "error",
            message: "Falha ao excluir profissional de saúde.",
          }) as any
        );
      });
  };
}

export function fetchDoctor(
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());

    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/doctors/${id}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.getDoctor(r.data.data.item));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e || "",
          }) as any
        );
      });
  };
}

export function updateDoctor(
  formData: {
    email: string;
    name: string;
    info: {
      phone: string;
      specialty: string;
      allowScheduling: boolean;
      schedule: any;
    };
  },
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/doctors/${id}`, formData, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(fetchListDoctors());
        dispatch(
          newNotification("doctor", {
            status: "success",
            message: "Profissional de saúde atualizado com sucesso",
          }) as any
        );
      })
      .catch((error) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("doctorError", {
            status: "error",
            message: "Falha ao editar informações do profissional de saúde.",
          }) as any
        );
        throw new Error();
      });
  };
}

export function createDoctor(formData: {
  email: string;
  name: string;
  info: { phone: string; specialty: string; allowScheduling: any };
}): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.API_URL}/doctors`, formData, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(setLabelMenu("doctor"));
        dispatch(fetchListDoctors());
        dispatch(
          newNotification("doctor", {
            status: "success",
            message: "Profissional de saúde criado com sucesso.",
          }) as any
        );
      })
      .catch((error) => {
        dispatch(actions.endLoading());
        if (
          error.response.data.error.message ===
          "Fail to create new Doctor: User with this Email already registered"
        ) {
          dispatch(
            newNotification("doctorError", {
              status: "error",
              message: "Profissional de saúde já existe com este email",
            }) as any
          );
        } else {
          dispatch(
            newNotification("doctorError", {
              status: "error",
              message: "Falha ao criar profissional de saúde.",
            }) as any
          );
        }
        throw new Error();
      });
  };
}

export function activeDoctor(
  id
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(
        `${ENVS.API_URL}/users/active/${id}`,
        {},
        {
          headers: {
            Authorization: apiToken,
          },
        }
      )
      .then((r) => {})
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("doctor", {
            status: "error",
            message: "Falha ao ativar profissional de saúde",
          }) as any
        );
      });
  };
}

export function fetchSpecialties(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/specialty`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.listSpecialties(r.data.data.items));
        return r.data.data.items;
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: e || "",
          }) as any
        );
      });
  };
}
