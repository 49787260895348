import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";

export type Room = {
  roomID: string;
  label: string;
};
export interface RoomState {
  room: Room;
  rooms: Array<Room>;
  label: string;
  loading: boolean;
}

export type InitialState = RoomState;

const initialState: InitialState = {
  room: {
    roomID: "",
    label: "",
  },
  label: "",
  rooms: [],
  loading: false,
};

// Selectors
const mainSelector = (state: RootState) => state.rooms;

/* export const getLoading = createSelector(
  mainSelector,
  (state) => {
    state.loading
  }
); */

export const getRooms = createSelector(mainSelector, (state) => {
  return {
    loading: state.loading,
    rooms: state.rooms,
    room: state.room,
    label: state.label,
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(a: boolean) {
    this.state.loading = a;
  }

  tokenSwap(formData: any, json: any) {
    let token = json.data.item.jwt
      ? json.data.item.jwt.replace("Bearer ", "")
      : "";
    return {
      form: formData,
      token,
      response: json,
      receivedAt: Date.now(),
    };
  }

  startLoading() {
    this.state.loading = true;
  }

  listRooms(rooms: Array<{ roomID: string; label: string }>) {
    this.state.rooms = rooms;
  }

  getRoom(room: { roomID: string; label: string }) {
    this.state.room = room;
  }

  clearRoom() {
    this.state.room = {
      roomID: "",
      label: "",
    };
  }

  clearRooms() {
    this.state.rooms = [];
  }

  endLoading() {
    this.state.loading = false;
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchListRooms(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/rooms`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.listRooms(r.data.data.items));
        dispatch(actions.endLoading());
        return r.data.data.items;
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("room", {
            status: "error",
            message: "Falha ao listar salas",
          }) as any
        );
      });
  };
}

export function createRoom(formData: {
  label: string;
  inactiveAt: any;
}): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.API_URL}/rooms`, formData, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(fetchListRooms());
        dispatch(
          newNotification("room", {
            status: "sucess",
            message: "Sala criada com sucesso!",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("room", {
            status: "error",
            message: "Falha ao criar a sala",
          }) as any
        );
      });
  };
}

export function fetchRoom(
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/rooms/${id}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.getRoom(r.data.data.item));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("room", {
            status: "error",
            message: "Falha ao listar sala",
          }) as any
        );
      });
  };
}

export function updateRoom(
  id: string,
  formData: {
    label: string;
    inactiveAt: any;
  }
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/rooms/${id}`, formData, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(fetchListRooms());
        dispatch(
          newNotification("room", {
            status: "sucess",
            message: "Sala atualizada com sucesso!",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("room", {
            status: "error",
            message: "Falha ao atualizar sala",
          }) as any
        );
      });
  };
}

export function deleteRoom(
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .delete(`${ENVS.API_URL}/rooms/${id}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then(() => {
        dispatch(fetchListRooms());
        dispatch(
          newNotification("room", {
            status: "sucess",
            message: "Sala inativada com sucesso!",
          }) as any
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        const error = (e.response.data.error || {}).message || "";
        console.log(error);
        const isFutureSchedules = error.match(
          /Fail to delete Room: Exists future schedules so it is not possible inactive this room/
        );
        const errorMessage2 = isFutureSchedules
          ? "Esta sala não pode ser apagada pois existem consultas marcadas."
          : "";
        dispatch(
          newNotification("room", {
            status: "error",
            message: errorMessage2 || "Falha ao inativar sala",
          }) as any
        );
      });
  };
}
