const envs = {
  API_URL: "https://inovantconsultoriostech.com.br:8080/api",
  BASE_URL: "https://inovantconsultoriostech.com.br:8080",
};

var localEnvs = {};
try {
  localEnvs = require("./envs").default;
} catch (ex) {
  console.log("error loading envs", ex);
}

export default {
  ...envs,
  ...localEnvs,
};
