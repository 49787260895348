import * as React from "react";
import { Route, Switch } from "react-router";
import { hot } from "react-hot-loader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Loadable from "react-loadable";
import Loader from "app/components/PageLoader";

const PasswordResetChoose = Loadable({
  loader: () => import("app/container/Auth/PasswordResetChoose"),
  loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    );
  }
});

const SignoutContainer = Loadable({
  loader: () => import("app/container/Auth/SignoutContainer"),
  loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    );
  }
});

const PasswordReset = Loadable({
  loader: () => import("app/container/Auth/PasswordReset"),
  loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    );
  }
});

const SecureContainer = Loadable({
  loader: () => import("app/container/Auth/SecureContainer"),
  loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    );
  }
});

const ContainerApp = Loadable({
  loader: () => import("app/container"),
  loading() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Loader />
      </div>
    );
  }
});

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#2ca1a5",
      main: "#2B918C",
      dark: "#1b4e5b"
    },
    secondary: {
      light: "#d5b740",
      main: "#d59541",
      dark: "#d57940"
    },
    background: {
      hover: "#f1f1f1"
    }
  }
} as any);

export const App = hot(module)(() => (
  <MuiThemeProvider theme={theme}>
    <Switch>
      <Route exact path="/password_reset" component={PasswordReset} />
      <Route
        path="/password_reset/:reset_id/:verification"
        component={PasswordResetChoose}
      />
      <Route exact path="/signout" component={SignoutContainer} />

      <SecureContainer>
        <Route path="/" component={ContainerApp} />
      </SecureContainer>
    </Switch>
  </MuiThemeProvider>
));
