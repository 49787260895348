import createReducer from "app/utility/createReducer";
import { RootState } from "app/ducks/state";
import { ThunkAction } from "redux-thunk";
import { createSelector } from "reselect";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";

export type User = {
  userID: string;
  name: string;
  email: string;
  username: string;
  info: any;
  created_at: Date;
  incative_at: Date;
  //role: Array<'admin' | 'externalDoctor' | 'internalDoctor'>;
};

export enum ActionTypes {
  LoadStart = "user.LoadStart",
  LoadList = "user.LoadList",
  LoadError = "user.LoadError",
  UpdateStart = "user.UpdateStart",
  CreateStart = "user.CreateStart",
  CreateEnd = "user.CreateEnd",
  CreateError = "user.CreateError",
  GetOneSuccess = "user.GetOneSuccess",
  UpdateError = "user.UpdateError",
  GetUserTo = "user.GetUserTo",
  clearUserTo = "clearUserTo"
}

export type Actions = {
  LoadStart: { type: ActionTypes.LoadStart };
  LoadList: { type: ActionTypes.LoadList; payload: Array<User> };
  LoadError: { type: ActionTypes.LoadError; payload: Error };
  UpdateStart: { type: ActionTypes.UpdateStart };
  CreateStart: { type: ActionTypes.CreateStart };
  CreateEnd: { type: ActionTypes.CreateEnd };
  CreateError: { type: ActionTypes.CreateError; payload: Error };
  GetOneSuccess: { type: ActionTypes.GetOneSuccess; payload: User };
  UpdateError: { type: ActionTypes.UpdateError; payload: Error };
  GetUserTo: { type: ActionTypes.GetUserTo; payload: User };
  clearUserTo: { type: ActionTypes.clearUserTo };
};

export type LoadingSections = {
  "user.list": boolean;
  "user.create": boolean;
  "user.update": boolean;
};

export type InitialState = {
  userList: Array<User>;
  loading: LoadingSections;
  currentUser?: User;
  error?: Error;
  userTo?: User;
};

// Reducers
const initialState: InitialState = {
  userList: [],
  loading: {
    "user.list": false,
    "user.create": false,
    "user.update": false
  },
  error: undefined
};

export const Reducer = createReducer(initialState, {
  [ActionTypes.LoadStart](state: InitialState, a: Actions["LoadStart"]) {
    state.loading["user.list"] = true;
    return state;
  },
  [ActionTypes.LoadList](state: InitialState, a: Actions["LoadList"]) {
    state.userList = a.payload;
    state.loading["user.list"] = false;
    return state;
  },
  [ActionTypes.LoadError](state: InitialState, a: Actions["LoadError"]) {
    state.error = a.payload;
    state.loading["user.list"] = false;
    return state;
  },
  [ActionTypes.UpdateStart](state: InitialState, a: Actions["UpdateStart"]) {
    state.loading["user.update"] = true;
    return state;
  },
  [ActionTypes.CreateStart](state: InitialState, a: Actions["CreateStart"]) {
    state.loading["user.create"] = true;
    return state;
  },
  [ActionTypes.CreateEnd](state: InitialState, a: Actions["CreateEnd"]) {
    state.loading["user.create"] = false;
    return state;
  },
  [ActionTypes.CreateError](state: InitialState, a: Actions["CreateError"]) {
    state.error = a.payload;
    state.loading["user.create"] = false;
    return state;
  },
  [ActionTypes.UpdateError](state: InitialState, a: Actions["UpdateError"]) {
    state.error = a.payload;
    state.loading["user.update"] = false;
    return state;
  },
  [ActionTypes.GetOneSuccess](
    state: InitialState,
    a: Actions["GetOneSuccess"]
  ) {
    state.currentUser = a.payload;
    state.loading["user.update"] = false;
    return state;
  },
  [ActionTypes.GetUserTo](state: InitialState, a: Actions["GetUserTo"]) {
    state.userTo = a.payload;
    state.loading["user.update"] = false;
    return state;
  },
  [ActionTypes.clearUserTo](state: InitialState, a: Actions["clearUserTo"]) {
    state.userTo = undefined;
    return state;
  }
});

// Selectors
const mainSelector = (state: RootState) => state.user;

export const getUserTo = createSelector(mainSelector, state => {
  return {
    userTo: state.userTo,
    loading: state.loading["user.update"]
  };
});

export const getUserList = createSelector(mainSelector, state => {
  return {
    userList: state.userList,
    loading: state.loading["user.list"]
  };
});

export function clearUserTo() {
  return {
    type: ActionTypes.clearUserTo
  };
}

// Actions
export function createUser(formData: {
  email: string;
  password: string;
  roles: Array<string>;
}): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch({ type: ActionTypes.CreateStart } as Actions["CreateStart"]);
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(`${ENVS.API_URL}/users`, formData, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(() => {
        dispatch({ type: ActionTypes.CreateEnd } as Actions["CreateEnd"]);
        dispatch(
          newNotification("user", {
            status: "sucess",
            message: "Usuário adicionado com sucesso!"
          }) as any
        );
        dispatch(fetchUserList());
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.CreateError,
          payload: e.response.data.error
        } as Actions["CreateError"]);
        dispatch(
          newNotification("user", {
            status: "error",
            message: "Falha ao criar o usuário"
          }) as any
        );
        throw new Error("Falha ao criar o usuário");
      });
  };
}

export function updateUser(
  formData: {
    email: string;
    roles: Array<string>;
    inactiveAt: string;
  },
  id: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch({ type: ActionTypes.UpdateStart } as Actions["UpdateStart"]);
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/users/${id}`, formData, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(() => {
        dispatch(
          newNotification("user", {
            status: "sucess",
            message: "Usuário atualizado com sucesso!"
          }) as any
        );
        dispatch(fetchUserList());
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.UpdateError,
          payload: e.response.data.error
        } as Actions["UpdateError"]);
        dispatch(
          newNotification("user", {
            status: "error",
            message: "Falha ao atualizar o usuário"
          }) as any
        );
        throw new Error("Falha ao atualizar o usuário");
      });
  };
}

export function fetchUserList(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch({ type: ActionTypes.LoadStart } as Actions["LoadStart"]);
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/users`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch({
          type: ActionTypes.LoadList,
          payload: r.data.data.items
        } as Actions["LoadList"]);
        return r.data.data.items;
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.LoadError,
          payload: e.response.data.error
        } as Actions["LoadError"]);
        dispatch(
          newNotification("user", {
            status: "error",
            message: e.response.data.error || ""
          }) as any
        );
      });
  };
}

export function fetchUser(
  userID: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    dispatch({ type: ActionTypes.UpdateStart } as Actions["UpdateStart"]);
    return axios
      .get(`${ENVS.API_URL}/users/${userID}`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch({
          type: ActionTypes.GetOneSuccess,
          payload: r.data.data.item
        } as Actions["GetOneSuccess"]);
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.UpdateError,
          payload: e.response.data.error
        } as Actions["UpdateError"]);
        dispatch(
          newNotification("user", {
            status: "error",
            message: (e.response.data.error || {}).message
          }) as any
        );
      });
  };
}

export function fetchUserTo(
  userID: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    const apiToken = "Bearer " + getState().auth.token.raw;
    dispatch({ type: ActionTypes.UpdateStart } as Actions["UpdateStart"]);
    return axios
      .get(`${ENVS.API_URL}/users/${userID}`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch({
          type: ActionTypes.GetUserTo,
          payload: r.data.data.item
        } as Actions["GetUserTo"]);
      })
      .catch(e => {
        dispatch({
          type: ActionTypes.UpdateError,
          payload: e.response.data.error
        } as Actions["UpdateError"]);
        dispatch(
          newNotification("user", {
            status: "error",
            message: (e.response.data.error || {}).message
          }) as any
        );
      });
  };
}

export function addPushToken(
  token: string
): ThunkAction<Promise<boolean>, RootState, any, any> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .post(
        `${process.env.API_PATH}/users/${state.auth.token.payload.userID}/push-tokens`,
        { token },
        {
          headers: {
            Authorization: apiToken
          }
        }
      )
      .then(r => {
        return true;
      })
      .catch(e => {
        dispatch(
          newNotification("user", {
            status: "error",
            message: "Falha ao habilitar notificações"
          }) as any
        );
        return false;
      });
  };
}
