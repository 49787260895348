import { createSelector } from "reselect";
import createReducer from "app/utility/createReducer";
import { RootState } from "./state";
import { jwt_payload } from "app/utility/jwt";
import { Action } from "ducks";
import { newNotification, dismissAllNotificationsFrom } from "./notification";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { getCookie, eraseCookie, setCookie } from "app/utility/cookie";
import axios from "axios";
import { User } from "app/ducks/user";
import ENVS from "../../config";

const REQUEST_SIGNIN = "REQUEST_SIGNIN";
const RESPONSE_SIGNIN = "RESPONSE_SIGNIN";
const FAIL_SIGNIN = "FAIL_SIGNIN";
const UNAUTHORIZED = "UNAUTHORIZED";
const TOKENSWAP = "TOKENSWAP";
const REQUEST_LOGINUSER = "REQUEST_LOGINUSER";
const FAIL_LOGINUSER = "FAIL_LOGINUSER";
const RESPONSE_LOGINUSER = "RESPONSE_LOGINUSER";
const COOKIENAME = "token";

const fromCookie = getCookie(COOKIENAME) || "";

export interface AuthState {
  token: {
    raw: string;
    payload: { [k: string]: any; exp: number };
  };
  loggedInUser?: User;
  loadingLoggedInUser: boolean;
}

export type InitialState = AuthState;

const initialState: InitialState = {
  token: {
    raw: fromCookie,
    payload: fromCookie ? jwt_payload(fromCookie) : { exp: 0 }
  },
  loadingLoggedInUser: false
};

// Reducers
export const authReducer = createReducer(initialState, {
  [REQUEST_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [RESPONSE_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [TOKENSWAP](state: InitialState, a: Action & { token: string }) {
    let token = a.token;
    state.token.raw = token;
    state.token.payload = jwt_payload(token);
    return state;
  },
  [FAIL_SIGNIN](state: InitialState, a: Action) {
    return state;
  },
  [UNAUTHORIZED](state: InitialState, a: Action) {
    state.token.raw = "";
    state.token.payload = { exp: 0 };
    return state;
  },
  [REQUEST_LOGINUSER](state: InitialState, a: Action) {
    state.loadingLoggedInUser = true;
    return state;
  },
  [RESPONSE_LOGINUSER](state: InitialState, a: Action) {
    state.loadingLoggedInUser = false;
    state.loggedInUser = a.payload;
    return state;
  },
  [FAIL_LOGINUSER](state: InitialState, a: Action) {
    state.loadingLoggedInUser = false;
    return state;
  }
});

// Selectors

const authSelector = (state: RootState) => state.auth;

export const getToken = createSelector(authSelector, auth => ({
  token: auth.token || {}
}));

export const getPayload = createSelector(authSelector, auth => ({
  payload: auth.token.payload || {}
}));

export const getLoggedInUser = createSelector(authSelector, auth => ({
  user: auth.loggedInUser,
  loading: auth.loadingLoggedInUser
}));

// Actions

export function requestSignin(formData: any) {
  return {
    type: REQUEST_SIGNIN,
    form: formData
  };
}

export function responseSignin(formData: any, json: any) {
  return {
    type: RESPONSE_SIGNIN,
    form: formData,
    response: json,
    receivedAt: Date.now()
  };
}

export function tokenSwap(formData: any, json: any) {
  let token = json.data.item.jwt
    ? json.data.item.jwt.replace("Bearer ", "")
    : "";
  const t = jwt_payload(token);
  const d = new Date(t.exp * 1000);
  setCookie(COOKIENAME, token, d);
  return {
    type: TOKENSWAP,
    form: formData,
    token,
    response: json,
    receivedAt: Date.now()
  };
}

function failSignin(formData: any, json?: any) {
  return {
    type: FAIL_SIGNIN,
    form: formData,
    response: json,
    receivedAt: Date.now()
  };
}

export function unauthorized() {
  eraseCookie(COOKIENAME);
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch({
      type: UNAUTHORIZED,
      receivedAt: Date.now()
    });
    dispatch({
      type: "authentication.logout"
    });
  };
}

export function login(formData: { email: string; password: string }): any {
  return async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(dismissAllNotificationsFrom("general"));
    dispatch(requestSignin(formData));
    return axios
      .post(`${ENVS.BASE_URL}/auth/signin`, formData)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.data;
        }
        return Promise.reject({ response });
      })
      .then(json => {
        dispatch(responseSignin(formData, json));
        dispatch(tokenSwap(formData, json));
        return Promise.resolve({ json });
      })
      .catch(e => {
        const error = (e.json || {}).error || "";
        const isWrongUser = error.match(/No user with this e/);
        const isWrongAuth = error.match(/Wrong email\/password/);
        const errorMessage = isWrongUser
          ? "Usuário desativado ou não existente."
          : "";
        const errorMessage2 = isWrongAuth ? "Email ou senha errados." : "";
        dispatch(
          failSignin(formData, {
            error: [errorMessage || errorMessage2 || "Falha ao fazer login."],
            ...e
          })
        );
        dispatch(
          newNotification("auth", {
            status: "error",
            message: errorMessage || errorMessage2 || "Falha ao fazer login."
          })
        );
        return Promise.reject({ e });
      });
  };
}

export function fetchLoggedInUser(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    dispatch({ type: REQUEST_LOGINUSER });
    return axios
      .get(`${ENVS.API_URL}/users/${state.auth.token.payload.userID}`, {
        headers: {
          Authorization: apiToken
        }
      })
      .then(r => {
        dispatch({
          type: RESPONSE_LOGINUSER,
          payload: r.data.data.item
        });
        return r.data.data.item;
      })
      .catch(e => {
        dispatch({
          type: FAIL_LOGINUSER,
          payload: e?.response?.data?.error
        });
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || {}).message,
          }) as any
        );
      });
  };
}
