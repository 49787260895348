import { hen, Hen } from "app/utility/createReducer";
import { createSelector } from "reselect";
import { RootState } from "ducks";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import { newNotification } from "./notification";
import ENVS from "../../config";
import { setLabelMenu } from "./sideMenu";
import moment from "moment";

export type Event = {
  scheID: string;
  doctID: string;
  docName: string;
  docTreatment: string;
  dataAppointment: string;
  startHour: string;
  endHour: string;
  roomID: string;
  patient: Array<Patient>;
  intervalTime: Interval;
};

export type Interval = {
  startInterval: string;
  endInterval: string;
};

export type Patient = {
  hourAppointment: string;
  patientName: string;
  status: string;
  type: string;
  appoID: string;
  patiID: string;
};

export type Room = {
  roomID: string;
  label: string;
};

export interface CalendarState {
  events: Array<Event>;
  rooms: Array<Room>;
  loading: boolean;
  namePatientsAndDoctors: Array<any>;
  timeLimit: Array<object>;
}

export type InitialState = CalendarState;

const initialState: InitialState = {
  rooms: [],
  events: [],
  loading: false,
  namePatientsAndDoctors: [],
  timeLimit: [],
};

// Selectors
const mainSelector = (state: RootState) => state.calendar;

/* export const getLoading = createSelector(
  mainSelector,
  (state) => {
    state.loading
  }
); */

export const getCalendars = createSelector(mainSelector, (state) => {
  return {
    //loading: state.loading,
    rooms: state.rooms,
    events: state.events,
    namePatientsAndDoctors: state.namePatientsAndDoctors,
    timeLimit: state.timeLimit,
  };
});

//Actions
class EditorReactions extends Hen<InitialState> {
  setLoading(a: boolean) {
    this.state.loading = a;
  }

  tokenSwap(formData: any, json: any) {
    let token = json.data.item.jwt
      ? json.data.item.jwt.replace("Bearer ", "")
      : "";
    return {
      form: formData,
      token,
      response: json,
      receivedAt: Date.now(),
    };
  }

  startLoading() {
    this.state.loading = true;
  }

  filterCanceledPatient(event: Event) {
    return {
      scheID: event.scheID,
      doctID: event.doctID,
      docName: event.docName,
      docTreatment: event.docTreatment,
      dataAppointment: event.dataAppointment,
      startHour: event.startHour,
      endHour: event.endHour,
      roomID: event.roomID,
      patient: event.patient.filter((p) => p.status !== "cancelled"),
    };
  }

  listEvents(e: Array<Event>) {
    this.state.events = e.map((event) =>
      this.filterCanceledPatient(event)
    ) as any;
  }

  listRooms(r: Array<Room>) {
    this.state.rooms = r;
  }

  getTimeLimit(t: Array<object>) {
    this.state.timeLimit = t;
  }

  endLoading() {
    this.state.loading = false;
  }
  getNamePatientsAndDoctors(patients: Array<any>, doctors: Array<any>) {
    const nameDoctors = doctors.map((doctor) => {
      return { doctID: doctor.doctID, name: doctor.name, type: "doctID" };
    });

    const namePatients = patients.map((patient) => {
      return { patiID: patient.patiID, name: patient.name, type: "patiID" };
    });

    this.state.namePatientsAndDoctors = [...nameDoctors, ...namePatients];
  }
}

//Reducers
export const [menuReducer, actions] = hen(new EditorReactions(initialState));
export default menuReducer;

export function fetchListEvents(
  startDate,
  endDate
): ThunkAction<Promise<any>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(setLabelMenu("calendar"));
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/calendar?startAt=${startDate}&endAt=${endDate}`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.listEvents(r.data.data.items));
        return axios.get(`${ENVS.API_URL}/configs/schedule-hour_config_flex`, {
          headers: {
            Authorization: apiToken,
          },
        });
      })
      .then((r) => {
        dispatch(actions.getTimeLimit(r.data.data.item.value["friday"][0]));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: /* (e.response.data.error || {}) */ "",
          }) as any
        );
      });
  };
}

export function fetchListRooms(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/rooms`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(actions.listRooms(r.data.data.items));
        return r.data.data.items;
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: /* (e.response.data.error || {}) */ "",
          }) as any
        );
        throw e;
      });
  };
}

export function fetchNamePatientsAndDoctors(): ThunkAction<
  Promise<void>,
  RootState,
  any,
  any
> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(`${ENVS.API_URL}/patients`, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        axios
          .get(`${ENVS.API_URL}/doctors`, {
            headers: {
              Authorization: apiToken,
            },
          })
          .then((h) =>
            dispatch(
              actions.getNamePatientsAndDoctors(
                r.data.data.items,
                h.data.data.items
              )
            )
          );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: "", //(e.response.data.error || {}).message,
          }) as any
        );
      });
  };
}

export function fetchListEventsBySearch(
  patiID,
  doctID
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .get(
        patiID
          ? `${ENVS.API_URL}/calendar?patiID=${patiID}`
          : `${ENVS.API_URL}/calendar?doctID=${doctID}`,
        {
          headers: {
            Authorization: apiToken,
          },
        }
      )
      .then((r) => {
        dispatch(actions.listEvents(r.data.data.items));
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: /* (e.response.data.error || {}) */ "",
          }) as any
        );
      });
  };
}

export function UpdateAppointment(
  formData: any
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(`${ENVS.API_URL}/appointments/${formData.appoID}`, formData, {
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        dispatch(
          fetchListEvents(
            moment().subtract("1", "month").format("YYYY-MM-DD"),
            moment().add("1", "month").format("YYYY-MM-DD")
          )
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: /* (e.response.data.error || {}) */ "",
          }) as any
        );
      });
  };
}

export function DeleteSchedule(
  scheID: string
): ThunkAction<Promise<void>, RootState, any, any> {
  return async (dispatch, getState) => {
    dispatch(actions.startLoading());
    const state = getState();
    const apiToken = "Bearer " + state.auth.token.raw;
    return axios
      .put(
        `${ENVS.API_URL}/schedules/${scheID}/deletedAt`,
        {},
        {
          headers: {
            Authorization: apiToken,
          },
        }
      )
      .then((r) => {
        dispatch(
          fetchListEvents(
            moment().subtract("1", "month").format("YYYY-MM-DD"),
            moment().add("1", "month").format("YYYY-MM-DD")
          )
        );
      })
      .catch((e) => {
        dispatch(actions.endLoading());
        dispatch(
          newNotification("general", {
            status: "error",
            message: /* (e.response.data.error || {}) */ "",
          }) as any
        );
      });
  };
}
