import { combineReducers, Reducer } from "redux";
import { RootState } from "./state";
import { Reducer as userReducer } from "./user";
import { authReducer } from "./auth";
import { noteReducer } from "./notification";
import patientReducer from "./patients";
import doctorReducer from "./doctors";
import scheduleReducer from "./schedule";
import axios from "axios";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import calendarReducer from "./calendar";
import roomReducer from "./rooms";
import sideMenuReducer from "./sideMenu";
import passwordReducer from "./password";
import outdoorReducer from "./outdoor";
export const history = createBrowserHistory();

export interface Action {
  type: string;
  payload: any;
}

export const reducers: Reducer<RootState> = combineReducers<RootState>({
  auth: authReducer,
  user: userReducer,
  notification: noteReducer,
  router: connectRouter(history),
  patients: patientReducer,
  doctors: doctorReducer,
  schedules: scheduleReducer,
  calendar: calendarReducer,
  rooms: roomReducer,
  sideMenu: sideMenuReducer,
  password: passwordReducer,
  outdoor: outdoorReducer
});

export const rootReducer = (state: RootState, action: any) => {
  /* if (action.type === "authentication.logout") {
    state = {} as any;
  } */

  return reducers(state, action);
};

axios.defaults.headers = {
  "Content-Type": "application/json"
};

export type Error = any;
